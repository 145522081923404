<template>
  <div class="pt-10 pb-20" v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }">
    <div class="card">
      <div class="card-header" style="background-color: #21B886">
        <h1 style="color: #ffffff;text-align: left;font-size: 18px;text-transform: uppercase;"
            class="panel-title">
          <i style="color: #f7ef00;" class="fas fa-1x fa-user-circle"></i>
          Passenger Sign Up
        </h1>
      </div>
      <div class="card-body">
        <div class="pt-10 pb-20" v-bind:class="{'container-fluid': widthFluid,container: !widthFluid}">
          <div class="row">
            <div class="col-sm-4 offset-sm-4">
              <div class="form-group">
                <h3 style="margin:20px 0px 20px 0px; text-align:center; color:#EC1C24; ">
                  Need an account?
                </h3>
                <h4 style="text-align: center; margin-bottom: 30px;">
                  Then sign up by your mobile number.
                </h4>
              </div>
              <div id="mobile-verify">
                <div class="form-group"><span class="input-level">
                                    <i class="far fa-fw fa-star text-danger"></i>
                                    Mobile Number:</span>
                  <input type="text" class="form-control" placeholder="01XXX######" name="mobile"
                         id="mobile" value=""/>
                  <i class="validation_msg"></i>
                </div>
                <div class="form-group"><span class="input-level">
                                    <i class="far fa-fw fa-star text-danger"></i>Name:</span>
                  <input type="text" class="form-control" placeholder="Full Name" name="name"
                         id="name" value=""/>
                  <i class="validation_msg"></i>
                </div>
                <div class="form-group">
                                    <span class="input-level">
                                      <i class="far fa-fw fa-star text-danger"></i>
                                      Password:</span>
                  <input type="password" class="form-control" name="userPass" id="userPass" value=""/>
                  <i class="validation_msg"></i>
                </div>
                <div class="form-group">
                                    <span class="input-level">
                                      <i class="far fa-fw fa-star text-danger"></i>
                                      Confirm Password:</span>
                  <input type="password" class="form-control" name="userPassConf" id="userPassConf"
                         value=""/>
                  <i class="validation_msg"></i>
                </div>
                <div class="form-group" style="margin:35px 0px;">
                  <div class="col-sm-6 offset-sm-3" align="center">
                    <button id="sign_up_btn" type="button" class="btn btn-warning btn-md btn-block">
                      SIGN UP
                    </button>
                  </div>
                </div>
              </div>
              <div id="code-verify">
                <div class="form-group col-md-12">
                  <h4 class="text-green" style="text-align: center;">
                    An OTP code sent to your given mobile number, please check
                    and type here.
                  </h4>
                </div>
                <div class="form-group col-md-12">
                                    <span class="input-level">
                                        <i class="far fa-fw fa-star text-danger"></i> OTP Code:</span>
                  <input type="text" class="form-control" id="verify_code" name="verify_code"
                         value=""/>
                  <i class="validation_msg verify_code_msg"></i>
                </div>
                <div class="form-group col-md-12" align="center">
                  <button type="button" id="verify" name="verify" class="btn btn-warning"
                          value="VERIFY CODE" onclick="OTPCodeVerification();">
                    VERIFY CODE
                  </button>
                </div>
              </div>
              <div id="signup-done">
                <div class="form-group col-md-12">
                  <h4 class="text-green" style="text-align: center;" id="signup-done-msg"></h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from "vuex";
import { REGISTER } from "@/core/services/store/auth.module";
import { LOGOUT } from "@/core/services/store/auth.module";

import { validationMixin } from "vuelidate";
import { email, required, minLength } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "register",
  data() {
    return {
      // Remove this dummy login info
      form: {
        email: "admin@demo.com",
        password: "demo"
      }
    };
  },
  validations: {
    form: {
      username: {
        required,
        minLength: minLength(3)
      },
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(3)
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        username: null,
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const username = this.$v.form.username.$model;
      const email = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signup_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send register request
        this.$store
          .dispatch(REGISTER, {
            email: email,
            password: password,
            username: username
          })
          .then(() => this.$router.push({ name: "dashboard" }));

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>


